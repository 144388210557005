import { render, staticRenderFns } from "./ruralGovernance.vue?vue&type=template&id=91656fb0&scoped=true"
import script from "./ruralGovernance.vue?vue&type=script&lang=js"
export * from "./ruralGovernance.vue?vue&type=script&lang=js"
import style0 from "./ruralGovernance.vue?vue&type=style&index=0&id=91656fb0&prod&scoped=true&lang=less"
import style1 from "./ruralGovernance.vue?vue&type=style&index=1&id=91656fb0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91656fb0",
  null
  
)

export default component.exports