<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="">
              <el-button type="success" size="small" @click="addOption">新增</el-button>
            </el-form-item>
            <el-form-item label="" style="margin-left: 2vw">
              <!-- <el-input v-model="searchName" placeholder="请输入名称"></el-input> -->
                <el-select
                    v-model="searchName"
                    placeholder="请选择乡村"
                    @change="nameChange"
                    >
                    <el-option
                        v-for="item in nameList"
                        :key="item.id"
                        :label="item.villageName"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
          </el-form>
        </div>
        <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button>
      </div>
    </div>
    <el-table
        v-loading="loading"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 1vh;">
      <!-- <el-table-column width="100" :index="indexMethod" label="序号" type="index" show-overflow-tooltip align="center"></el-table-column> -->
      <el-table-column prop="totalHouseholds" label="总户数" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="totalPeoples" label="总人数" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="permanentPeoples" label="常住人口数" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="migrantPopulation" label="流动人口数" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="gdp" label="生产总值" show-overflow-tooltip align="center">
          <template slot-scope="scope">
              <span>{{scope.row.gdp}}{{scope.row.gdpUnit}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="annualIncome" label="村民人均年收入" show-overflow-tooltip align="center">
          <template slot-scope="scope">
              <span>{{scope.row.annualIncome}}{{scope.row.annualIncomeUnit}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="insuredNumber" label="低保户数" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="shiduNumber" label="失独人数" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="handicappedNumber" label="残疾人数" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="fiveGuaranteedNumber" label="五保户人数" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <!-- <el-button
            type="primary"
            size="small"
            icon="el-icon-document"
            @click="details(scope.row)"
          ></el-button> -->
          <el-button
            type="primary"
            size="small"
            icon="el-icon-edit"
            @click="edits(scope.row)"
          ></el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-delete"
            @click="deletes(scope.row.id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
    <!--    编辑-->
    <el-dialog title="" :visible.sync="messageBox" width="31vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"></span>
      </div>
      <div class="addAlaForm">
        <el-form ref="addForm" :rules="rules" :model="addForm" label-width="10vw">
          <el-form-item label="乡村名称" prop="villageId">
            <el-select
                v-model="addForm.villageId"
                placeholder="请选择乡村名称"
                @change="villNameChange"
                >
                <el-option
                    v-for="item in nameList"
                    :key="item.id"
                    :label="item.villageName"
                    :value="item.id"
                ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="总户数" prop="totalHouseholds">
            <el-input type="number" :disabled="true" v-model="addForm.totalHouseholds" placeholder="请输入总户数"></el-input>
          </el-form-item>
          <el-form-item label="总人数" prop="totalPeoples">
            <el-input type="number" :disabled="true" v-model="addForm.totalPeoples" placeholder="请输入总人数"></el-input>
          </el-form-item>
          <el-form-item label="常住人口数" prop="partyMember">
            <el-input type="number" v-model="addForm.permanentPeoples" placeholder="请输入常住人口数"></el-input>
          </el-form-item>
          <el-form-item label="流动人口数" prop="migrantPopulation">
            <el-input type="number" v-model="addForm.migrantPopulation" placeholder="请输入流动人口数"></el-input>
          </el-form-item>
          <el-form-item label="生产总值" prop="gdp">
            <el-input type="number" v-model="addForm.gdp" placeholder="请输入生产总值"></el-input>
          </el-form-item>
          <el-form-item label="生产总值单位" prop="gdpUnit">
            <el-input v-model="addForm.gdpUnit" placeholder="请输入生产总值单位"></el-input>
          </el-form-item>
          <el-form-item label="村民人均年收入" prop="annualIncome">
            <el-input type="number" v-model="addForm.annualIncome" placeholder="请输入村民人均年收入"></el-input>
          </el-form-item>
          <el-form-item label="村民人均年收入单位" prop="annualIncomeUnit">
            <el-input v-model="addForm.annualIncomeUnit" placeholder="请输入村民人均年收入单位"></el-input>
          </el-form-item>
          <el-form-item label="低保户数" prop="insuredNumber">
            <el-input type="number" v-model="addForm.insuredNumber" placeholder="请输入低保户数"></el-input>
          </el-form-item>
          <el-form-item label="失独人数" prop="shiduNumber">
            <el-input type="number" v-model="addForm.shiduNumber" placeholder="请输入失独人数"></el-input>
          </el-form-item>
          <el-form-item label="残疾人数" prop="handicappedNumber">
            <el-input type="number" v-model="addForm.handicappedNumber" placeholder="请输入残疾人数"></el-input>
          </el-form-item>
          <el-form-item label="五保户人数" prop="fiveGuaranteedNumber">
            <el-input type="number" v-model="addForm.fiveGuaranteedNumber" placeholder="请输入五保户人数"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" v-show="showFlag">
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button type="primary" @click="saveForm('addForm')">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      ids: 0,
      searchName: '',
      time1: '',
      addForm: {},
      rules: {
        villageId: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
        totalHouseholds: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
        totalPeoples: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
        permanentPeoples: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
        migrantPopulation: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
        gdp: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
        gdpUnit: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
        annualIncome: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
        annualIncomeUnit: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
        insuredNumber: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
        shiduNumber: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
        handicappedNumber: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
        fiveGuaranteedNumber: [
          {required: true, message: "不可为空", trigger: ["blur", "change"]},
        ],
      },
      formTitle: "",
      messageBox: false,
      showFlag: false,
      value: true,
      typeList: [
          { id: 1, name: '农事服务中心'},
          { id: 2, name: '服务立站点'},
      ],
      nameList: [],
    };
  },
  mounted() {
      this.getVillageId();
    this.getList();
  },
  methods: {
    indexMethod(index) {
      return index + this.size * (this.page - 1) + 1;
    },
    nameChange(){
        this.getList();
    },
    villNameChange(val){
        this.$get("/ruralOverview/getRuralOverviews", {
            page:1,
            size: 1000,
            villageId: val
        }).then((res) => {
            if(res.data.state == 'success'){
                this.getVillageId();
                this.addForm.totalHouseholds = res.data.datas[0].totalHouseholds;
                this.addForm.totalPeoples = res.data.datas[0].totalPeoples;
                //console.log(this.addForm.totalHouseholds)
            }
        })
    },
    //请求列表数据
    getList() {
      this.$get("/ruralGovernance/getRuralGovernances", {
        page: this.page,
        size: this.size,
        villageId: this.searchName
      }).then((res) => {
        if (res.data.state == "success") {
          this.tableData = res.data.datas;
          this.total = res.data.count;
          //console.log("获取列表", this.tableData)
        }
      });
    },
    // 乡村id
    getVillageId(){
        this.$get("/villageIntroduction/getVillageIntroductions", {
        page: this.page,
        size: 1000,
      }).then((res) => {
        if (res.data.state == "success") {
          this.nameList = res.data.datas;
        }
      });
    },
    // 编辑
    edits(row) {
      this.showFlag = true;
      this.ids = row.id;
      this.formTitle = "编辑";
      this.messageBox = true;
      this.addForm = JSON.parse(JSON.stringify(row));
    },
    // 删除
    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/ruralGovernance/delRuralGovernanceById", {
            id: id,
          }).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch((e) => e);
    },
    // 确认提交
    saveForm(formName) {
      this.addForm.id = this.ids;
      let url = '/ruralGovernance/addOrUpdateRuralGovernance';
      if(this.formTitle == '新增'){
        delete this.addForm.id;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(url, this.addForm
          ).then((res) => {
            if (res.data.state == 'success') {
              if(this.formTitle == '新增'){
                this.$message.success('添加成功')
              }else{
                this.$message.success('修改成功')
              }    
              this.messageBox = false
              this.getList();
            } else {
              this.$message.error(res.data.msg)
              this.messageBox = false
            }
          })
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    // 新增
    addOption() {
      this.formTitle = "新增";
      this.messageBox = true;
      this.showFlag = true;
      this.$nextTick(() => {
        this.addForm = {};
        this.$refs.addForm.resetFields();
      });
    },
    search() {
      //console.log(11111)
      this.page = 1
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button, .el-button--info {
  margin-left: 10px !important;

}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 1vw;
}
.isUpload /deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 12vh;
  height: 12vh;
}
.isUpload /deep/.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.isUpload /deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 12vh;
  height: 12vh;
  line-height: 12vh;
  text-align: center;
}
.isUpload .businessImg {
  width: 12vh;
  height: 12vh;
}
.imgs{
    width: 6vh;
    height: 6vh;
}

</style>


